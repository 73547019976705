
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'
  import { mapActions } from 'vuex'
  import { Stock } from '@/entities/sales'
  import dayjs from 'dayjs'

  interface IAppraisalResponse {
    title: string,
    value: string,
  }

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
  methods: {
    ...mapActions('resources/form', [
      'getAppraisalWithAutoId',
    ]),
    fixPrice,
  },
})

  export default class StockAppraisalResponses extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: Stock
  responses: IAppraisalResponse[] = []

  getAppraisalWithAutoId!: (autoId) => Promise<Record<string, any>>

  @Watch('value', { immediate: true, deep: true })
  async getAppraisalResponses () {
    if (!this.idAuto || !this.idAppraisal) return
    const { idAuto, idAppraisal } = this

    this.responses = []

    const appraisal = (await this.getAppraisalWithAutoId(idAuto))
      ?.find(appraisal => appraisal.id === idAppraisal)

    const { lastAppraisalResponse, lastAppraisedConsignmentResponse } = appraisal

    this.responses = [
      { title: 'Fecha de tasación', value: dayjs(appraisal.createdAt).format('DD/MM/YYYY HH:mm') },
      { title: 'Oferta compra', value: fixPrice(Number(lastAppraisalResponse || 0)) },
      { title: 'Oferta consignación', value: fixPrice(Number(lastAppraisedConsignmentResponse || 0)) },
    ]
  }

  get idAuto () {
    if (this.value instanceof Stock) {
      return this.value?.auto?.id
    }
  }

  get idAppraisal () {
    if (this.value instanceof Stock) {
      return this.value?.viewDetails?.idAppraisal
    }
  }
  }
